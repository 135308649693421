import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import SEO from '../../components/SEO'
import '../../styles/game-ebook.less'
import { EbookPageForm } from '../../components/form-biz'
const loadText = require('src/utils').loadText
const data = loadText('game-ebook')
const TopBanner = () => (
  <div className="game-ebook-topBanner">
    <div className="game-ebook-topBanner-bg">
      <div className="headerContainer ">
        <Header logo="/logo-blue.svg" />
      </div>
      <div className="game-ebook-topBanner-content">
        <div className="left">
          <div className="title">{data.topBanner.title}</div>
          <div className="subtitle">{data.topBanner.subtitle}</div>
        </div>
        <div className="right">
          <img src="/game-ebook/topBanner-pic.png" alt="icon" />
        </div>
      </div>
    </div>
  </div>
)
const Form = () => (
  <div className="game-ebook-form">
    <div className="game-ebook-form-bg">
      <div className="game-ebook-form-content">
        <div className="left">
          {data.form.content.paragraphs.map((p) => (
            <div>{p}</div>
          ))}
          <ul>
            {data.form.content.list.map((l) => (
              <li>{l}</li>
            ))}
          </ul>
        </div>
        <div className="right">
          <div className="right-title">{data.form.title}</div>
          <EbookPageForm
            source="ebook/game"
            onSuccess={() => {
              location.href = '/visme/v/z46vnpoy-grw04y6'
            }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default function Home() {
  return (
    <div className="game-ebook">
      <SEO
        {...data.seo}
        featuredImage="/featuredImage/zenlayer.jpg"
        noIndex={true}
      />
      <TopBanner />
      <Form />
      <Footer />
    </div>
  )
}
